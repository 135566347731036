<template>
  <users-list></users-list>
</template>

<script>
import UsersList from '@/modules/users/UsersList.vue'

export default {
  components: {
    UsersList,
  },
}
</script>

<style>

</style>
